export const PAGE_NAMES = {
    POWER_BI_REPORT_MANAGEMENT: "PowerBI Report Management",
    ADMIN: "Admin",
    REPORTS_AND_ROLES: "Reports & Roles",
    GLOSSARY: "Glossary",
    MASTER_DATA: "Master Data",
    ENTITIES: "Entities",
    ORGANIZATIONS: "Organizations",
    STATE_PROFILES: "State Profiles",
    DATA_STREAMS: "Data Streams",
    FILINGS_AND_NOTICES: "Filings & Notices",
    REIMBURSEMENTS: "Reimbursements",
    USER_MANAGEMENT: "User Management",
    REPORT_LIBRARY: "Report Library",
    AD_HOC_QUERY: "Ad-hoc Query",
    REVIEWS_AND_APPROVALS: "Reviews & Approvals",
    NOTIFICATIONS: "Notifications",
    FORECASTING: "Forecasting",
    REVENUE_RECOGNITION: "Revenue Recognition",
    ESCHEATMENT: "Escheatment",
    DASHBOARD: "Dashboard",
    DATA_LAYOUTS: "Data Layouts",
    SIMULATIONS: "Simulations",
}

export const PAGE_BREADCRUMBS = {
    FORECASTING: "At A Glance: Forecasting",
    REVENUE_RECOGNITION: "At A Glance: Revenue Recognition",
    ESCHEATMENT: "At A Glance: Escheatment",
}